import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import userStore from './userStore'
import todoListStore from './TodoListStore.js'
import shopProductStore from './ShopProductStore';

let drawler = localStorage.getItem('drawler') === null || localStorage.getItem('drawler') === 'true';

const store = new Vuex.Store({
  modules: {
    // 키: 값 형태로 저장됩니다.
    userStore: userStore,
    todoListStore: todoListStore,
    shopProductStore: shopProductStore,
  },


  state: {
    count: 10,
    todoCategories: [
      'HOBBY',
      'WORK',
      'STUDY'
    ],

    // 네비바
    barColor: 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
    barImage: '',
    drawer: drawler,
    contentNavigation: null
  },
  getters: {
    GE_COUNT: (state) => {
      return state.count;
    }
  },
  mutations: {
    MU_ADD_COUNT: (state) => {
      state.count++;
    },


    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      localStorage.setItem('drawler', payload);
      state.drawer = payload
    },


    SET_CONTENT_NAVIGATION(state, payload) {
      state.contentNavigation = payload;
    },

    UNSET_CONTENT_NAVIGATION(state) {
      state.contentNavigation = null
    },
  },
  actions: {
    AC_ADD_COUNT: ({ commit }) => {
      commit('MU_ADD_COUNT');
    }
  }

})

export default store
