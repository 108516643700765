<template>
  <div>
    <!-- <LoginHeader></LoginHeader> -->
    <DialogVue></DialogVue>
    <Notification></Notification>

    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import Notification from '@/components/Notification';
import DialogVue from '@/components/Dialog.vue';
export default ({
  components: {
    DialogVue,
    Notification,
  },
  mounted() {
    this.updateTitle();
  },
  methods: {
    updateTitle() {
      let tryCnt = 0;
      let trySetTitle = function () {
        setTimeout(function () {
          try {
            let title = document.querySelector('.v-main h2').innerText;
            if (tryCnt < 100) {
              if (!title.trim()) {
                tryCnt++;
                trySetTitle();
                return;
              }
            }
            document.title = title;
          } catch (e) {
            document.title = 'IBL';
            if (tryCnt < 100) {
              trySetTitle();
              tryCnt++;
            }
          }
        }, 30);
      };

      trySetTitle();
    },
  },
  watch: {
    $route () {
      this.updateTitle();
    }
  }
})
</script>