import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import AceEditor from '@/plugins/ace-editor'
import axios from 'axios';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import lodash from 'lodash';
import { i18n } from './lang/i18n';
import utils from './common/utils';

import './scss/app.scss';

import DefaultLayout from './layouts/Default.vue'
import StandAlone from './layouts/StandAlone';
import BlogLayout from './layouts/Blog.vue';

Vue.component("layout-default", DefaultLayout);
Vue.component("layout-alone", StandAlone);
Vue.component("layout-blog", BlogLayout);

Vue.config.productionTip = false


axios.interceptors.request.use(
  config => {
    // if (config.url.indexOf('/api/v1') === 0) {
    //   const accessToken = JSON.parse(localStorage.getItem('Authorization') || '{}');
    //   if (accessToken.value) {
    //     if (accessToken.expire < new Date().getTime()) {
    //       let loginPath = location.href.indexOf('/#/') !== -1 ? '/#/login' : '/login';
    //       localStorage.setItem('Authorization', '');
    //       location.replace(loginPath);
    //       return;
    //     } else {
    //       config.headers['Authorization'] = accessToken.value;
    //     }
    //   }
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   function (response) {
//     // 응답 데이터를 가공
//     // ...
//     console.log('응답 인터셉터 response', response);
//     return response;
//   },
//   function (error) {
//     // 오류 응답을 처리
//     // ...

//     console.log('응답 인터셉터 error', error);
//     return Promise.reject(error);
//   }
// );


Vue.prototype.$http = axios;

Vue.prototype._ = lodash

Vue.prototype.utils = utils;

Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday'
  ],
  dayOfWeekNamesShort: [
    '일', '월', '화', '수', '목', '금', '토'
  ],
  monthNames: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  monthNamesShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ],
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  timezone: 540 // 9시간 * 60
});
Vue.use(Notifications); // http://vue-notification.yev.io/

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value.toUpperCase();
})

Vue.filter('ymd', function (value) {
  if (!value) return ''
  return value.replace(/-/g, '.');
})

Vue.filter('number', function (value) {
  if (value === 0) {
    return 0;
  }
  if (!value) return '';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

import store from '@/vuex/index.js'

Vue.component("ace-editor", AceEditor);

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
