import _ from 'lodash';


function diffImages(cdnList, localList) {
  if (!localList) {
    return [];
  }
  let imgs = [];
  let names = [];
  let isSyncNeeded = false;
  localList.map(o => {

    if (!/^(body|main|option)-/.test(o.name)) {
      return;
    }
    let exists = false;
    cdnList.map(oo => {
      if (o.name == oo.name) {
        let tmp = {
          name: o.name,
          size: o.size,
          ts: o.mtimeMs,
        };
        tmp._size = oo.size;
        tmp._ts = oo.ts;
        imgs.push(tmp);
        names.push(oo.name);
        exists = true;
      }
    });
    if (!exists) {
      isSyncNeeded = true;
      imgs.push({
        name: o.name,
        size: o.size,
        ts: o.mtimeMs,
        error: 'CDN 에 파일없음'
      })
    }
  })

  cdnList.map(o => {
    if (names.indexOf(o.name) !== -1) {
      return;
    }
    imgs.push({
      name: o.name,
      error: '로컬에 파일없음',
      size: 0,
      ts: 0,
      _ts: o.ts,
      cdn: o
    })
  });

  imgs = _.sortBy(imgs, ['ts']).reverse()

  return {
    isSyncNeeded: isSyncNeeded,
    images: imgs
  };

}

export default {
  diffImages: diffImages
}