
<template>

  <v-card>
    <!-- v-navigation-drawer 옵션
        :src="barImage"
        -->
    <v-navigation-drawer id="core-navigation-drawer" v-model="drawer"
                         :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'" :src="barImage"
                         :expand-on-hover="expandOnHover" :right="$vuetify.rtl" mobile-breakpoint="960" app width="250" v-bind="$attrs">


      <template v-slot:prepend>
        <div style="height: 63px;" class="pl-3 pt-3">
          <div class="float-right mt-5 mr-3">Admin</div>
          <router-link to="/dashboard">
            <v-img src="https://cdn.vuetifyjs.com/docs/images/logos/vuetify-logo-light-text.svg" />
          </router-link>
        </div>
        <v-divider></v-divider>
      </template>



      <!-- <v-subheader>Menus</v-subheader> -->
      <v-list class="sidebar-menu">

        <div v-for="item in items" :key="item.path">

          <v-list-item v-if="!item.items">
            <v-list-item-icon>
              <v-icon>{{item.action}}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>
              <router-link class="font-weight-bold" :to="item.path" :class="item.active ? 'active' : ''">{{item.title}}
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-list-group v-if="item.items" :key="item.title" v-model="item.active">

            <template v-slot:activator>
              <v-list-item-icon class="mr-4">
                <v-icon :class="item.action.indexOf('fa') == 0 ? '' : 'ml-1'" v-text="item.action"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold" v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="child in item.items" :key="child.title"
                         style="margin-left: 25px; border-left: 1px solid rgba(0, 0, 0, 0.12) !important;">
              <v-list-item-content>
                <v-list-item-title>
                  <!-- <template v-if="child.path && !child.active">
                    <router-link :to="child.path" :class="child.active ? 'active' : ''">
                      {{child.title}}
                    </router-link>
                  </template>
                  <template v-else>
                    <a class="active">{{child.title}}</a>
                  </template> -->
                  <router-link v-if="child.path" :to="child.path" :class="child.active ? 'active' : ''">
                    {{child.title}}
                  </router-link>
                  <span v-if="!child.path">{{child.title}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

        </div>

        <template>
          <v-divider></v-divider>
          <v-subheader class="ml-1">Extras</v-subheader>

          <v-list-item style="height: 25px;">
            <v-list-item-action class="mr-4">
              <v-icon v-if="!userProfile || !userProfile.picture">login</v-icon>
              <v-avatar v-if="userProfile && userProfile.picture" size="20px">
                <v-img :src="userProfile.picture"></v-img>
              </v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <a @click="goLogin()">
                  <template v-if="userProfile && userProfile.name">
                    <span id="login_name" style="display:none">{{userProfile.name}}</span> Logout
                  </template>
                  <template v-else>
                    <span id="login_name" style="display:none">GUEST</span>Login
                  </template>
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-action>
              <v-icon>insert_drive_file</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <a href="/service/cafe24/rest/cafe24_authorize">카페24 인증</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </template>
        <!-- <v-list-item>
          <v-list-item-action>
            <v-icon>insert_drive_file</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <router-link v-if="!userToken" to="/login">마케팅센터 로그인</router-link>
              <span v-if="userToken" text @click="logout()" class="text-left pl-0 ml-0">마케팅센터 로그아웃</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
 -->

      </v-list>


    </v-navigation-drawer>
  </v-card>
</template>


<script>
import { mapState, mapGetters, mapActions } from 'vuex'

const userStore = 'userStore';

export default {
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
  }),
  methods: {
    ...mapActions(userStore, [
      'AC_UPDATE_PROFILE'
    ]),
    onUpdate(selection) {
      console.log(selection)
    },
    onSelect(ooo) {
      console.log(ooo);
    },


    goLogin() {
      let api = '/service/auth/google_authorize';
      if (this.userProfile && this.userProfile.name) {
        api = '/service/auth/logout';
      } else {
        localStorage.setItem('__hulk_default_path', this.$route.path);
      }
      // console.log(this.$route.path, api);
      location.replace(api);
    },


    updateSidebarMenu(items) {
      let currentPath = this.$route.meta.parentMenu || this.$route.path;

      items.forEach(m => {
        // console.log(mm.path, 'VS', currentPath);
        if (m.path && currentPath.indexOf(m.path) == 0) {
          m.active = true;
        } else {
          m.active = false;
        }
        if (!m.items) {
          return;
        }
        m.items.forEach(mm => {
          // console.log(mm.path, 'VS', currentPath);
          if (mm.path && currentPath.indexOf(mm.path) == 0) {
            m.active =true;
            mm.active = true;
          } else {
            mm.active = false;
          }
        });
      });

      return items;
    },


    updateTitle() {
      let tryCnt = 0;
      let trySetTitle = function() {
        setTimeout(function () {
          try {
            document.title = document.querySelector('.v-main h2').innerText;
          } catch (e) {
            document.title = 'IBL';
            if (tryCnt < 100) {
              trySetTitle();
              tryCnt++;
            }
          }
        }, 30);
      };
      
      trySetTitle();
      
      
    },

    async init() {
      return new Promise((resolve) => {
        this.isNodeBackendMode = true;
        this.$http.get('/service/auth/getAuthorizedMenus').then(res => {
          let o = res.data;
          if (!o.code || o.code !== 'SUCCESS') {
            this.$notify({
              title: "API 응답 오류",
              text: '메뉴정보를 가져오는데 실패하였습니다.'
            });
          }

          this.items = this.updateSidebarMenu(o.data.menus);
          let profile = o.data.profile || {};
          profile.menus = o.data.menus;

          if (o.data.DEV_IGNORE_AUTH_CHECK) {
            profile.DEV_IGNORE_AUTH_CHECK = true;
          }

          profile.authButtons = o.data.authButtons || [];

          this.AC_UPDATE_PROFILE(profile);
          // this.items = getSidebarMenu();

          resolve(profile);
        }, e => {
          console.error(e);
          this.$notify({
            title: "API 호출 오류",
            text: 'api 와 통신할 수 없습니다.',
            type: 'error'
          });
        });
      })
      
    },

  },
  created() {
    this.init();
  },
  watch:{
    $route (){
      this.updateSidebarMenu(this.items);
      this.updateTitle();
    },
  },
  computed: {
    ...mapState(['barColor', 'barImage', "profile"]),
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    computedItems () {
      return this.items.map(this.mapItem)
    },
    profile () {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
    ...mapGetters(userStore, {
      userProfile: 'GE_PROFILE'
    }),
  },
}
</script>
<style scoped>

.sidebar-menu .v-list-item__title {
    cursor: pointer;
    font-size: 14px;
}

/* .sidebar-menu .v-list-item__content {
    padding: 0 0 !important;
    height: 25px !important;
} */

.sidebar-menu .v-list-item {
    padding: 0 0 0 20px !important;
    min-height: 16px !important;
}

.sidebar-menu .v-list-item__icon {
    margin: 12px 0;
}

.sidebar-menu .v-list-item__icon:first-child {
    margin-right: 20px;
}

.sidebar-menu .v-list-item__title a{
    color: rgba(0,0,0,.87);
}

.sidebar-menu .v-list-item__title a.active{
    color: rgb(25, 118, 210);
}

</style>