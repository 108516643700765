<template>
  <v-app-bar app color="primary" dark clipped-right elevate-on-scroll>
    <v-app-bar-nav-icon @click="setDrawer(!drawer)"></v-app-bar-nav-icon>

    <div class="d-flex align-center">
      <v-img alt="Vuetify Logo" class="shrink mr-2" contain
             src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition" width="40" />

      <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
             src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" />
    </div>

    <v-spacer></v-spacer>

    <!-- <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text>
      <span class="mr-2">Latest Release</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn> -->

  </v-app-bar>
</template>

<script>
// Utilities
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DefaultHeader',
  components: {
    
  },

  props: {

  },

  data: () => ({
    userType: 'USER',
  }),

  mounted() {
    const accessToken = localStorage.getItem('Authorization');
    if (accessToken) {
      this.userType = JSON.parse(accessToken).userType;
    }

    this.updateSearch(JSON.parse(localStorage.getItem('isSearch')));
  },

  computed: {
    ...mapState(['drawer']),
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
    logout() {
      this.$cookies.remove("WP-ID");
      localStorage.removeItem('userId');
      localStorage.removeItem('Authorization');
      localStorage.removeItem('isSearch');
      localStorage.removeItem('search');
      localStorage.removeItem('searchAccountList');
      localStorage.removeItem('searchSiteList');
      this.$router.push('/login').catch(() => { this.$router.go() });
    },
    changeUserType() {
      localStorage.setItem('isSearch', false);
      localStorage.removeItem('search');
      localStorage.removeItem('searchAccountList');
      localStorage.removeItem('searchSiteList');
      this.updateSearch(false);
      this.$router.push('/').catch(() => { this.$router.go() });
    },
    updateSearch(value) {
      this.$emit('updateSearch', Boolean(value));
    }
  },
}
</script>
