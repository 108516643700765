<template>
  <div class="global-notification">
    <notifications
      animation-name="v-fade-left"
      position="top right"
      style="margin-top: 80px;" class="mr-2" />
  </div>
</template>

<script>

export default {
  data() {
    return {
      toast: {},
    }
  },
  methods: {
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    // this.showToast();
  }
};
</script>



<style lang="scss">

.v-fade-left-enter-active,
.v-fade-left-leave-active,
.v-fade-left-move {
    transition: all .5s;
}
.v-fade-left-enter,
.v-fade-left-leave-to {
    opacity: 0;
    transform: translateX(500px) scale(0.2);
}

.vue-notification.error {
    background: #e54d42;
    border-left-color: #b82e24 !important;
}

.global-notification .vue-notification.warning {
    background: #ffb648;
    border-left-color: #f48a06 !important;;
}

.global-notification .vue-notification.error {
    background: #E54D42;
    border-left-color: #B82E24 !important;;
}
.global-notification .vue-notification.success {
    background: #68CD86;
    border-left-color: #42A85F !important;
}

</style>
