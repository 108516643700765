
import axios from 'axios'

const todoListStore = {
  namespaced: true,
  state: {
    todoList: []
  },
  getters : {
    GE_TODO_LIST (state) {
      return state.todoList;
    }
  },
  mutations: {
    MU_UPDATE_STATUS : (state, payloads) => {
      state.todoList = payloads;
    },
  },
  actions: {
    AC_GET_TODO_LIST : ({ commit }) => {
      axios.get('/service/todolist/getTodoList') .then(res => {
        commit('MU_UPDATE_STATUS', res.data.data);
      });
    },
    AC_ADD_ITEM : ({ commit }, payload) => {
      axios.post('/service/todolist/addTodoList', {payload: payload} ).then(res => {
        commit('MU_UPDATE_STATUS', res.data.data);
      });
    },
    AC_UPDATE_ITEM : ({ commit }, payload) => {
      axios.post('/service/todolist/updateTodoList', {payload: payload} ).then(res => {
        commit('MU_UPDATE_STATUS', res.data.data);
      });
    },
    AC_DELETE_ITEM : ({ commit }, payload) => {
      axios.post('/service/todolist/deleteTodoList', {payload: payload} ).then(res => {
        commit('MU_UPDATE_STATUS', res.data.data);
      });
    },
  }
}


export default todoListStore
