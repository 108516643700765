import { mapGetters, mapActions } from 'vuex'

const userStore = 'userStore';
let watchers = {};

export default {
  template: `<div v-if="isAuthorizedPage == 'false'" class="ma-5">NOT AUTHORIZED</div>`,
  created() {
    this.handleAutoOptions();
  },
  mounted() {
    setTimeout(function (self) {
      if (self.isAuthorizedPage == null) {
        self.checkAuth();
      }
    }, 500, this);
  },
  methods: {
    handleAutoOptions(o) {
      if (typeof this.auto_opts === 'object') {
        let storageKey = 'auto_opts_' + this.$route.matched[0].path.split('/:')[0];
        if (o) {
          // console.log('@@ 자동 페이지옵션 강제 업데이트!! :', storageKey);
          localStorage.setItem(storageKey, JSON.stringify(o));
        }
        let opts = localStorage.getItem(storageKey) || '{}';

        // console.log('@@ 자동 페이지옵션 로딩됨:', storageKey, opts);
        opts = JSON.parse(opts);
        if (Object.keys(opts).length !== 0) {
          this.auto_opts = opts;
        }

        if (watchers[storageKey]) {
          watchers[storageKey]();
        }

        // console.log('@@ 자동 페이지옵션 처리 시작 localStorage key :', storageKey);
        watchers[storageKey] = this.$watch('auto_opts', function (n) {
          // console.log('@@ 자동 페이지옵션 업데이트!! :', storageKey);
          if (Object.keys(n).length === 0) {
            return;
          }
          localStorage.setItem(storageKey, JSON.stringify(this.auto_opts));
        }, {
          deep: true
        });
      }
    },
    ...mapActions(userStore, [
      'AC_UPDATE_CURRENT_PAGE_AUTH'
    ]),
    // 메뉴 권한을 체크합니다.
    checkAuth(userProfile) {
      if (!userProfile) {
        userProfile = this.userProfile;
      }
      if (!userProfile.menus || this.$route.path === '/') {
        return;
      }

      let currentPath = this.$route.meta.parentMenu || this.$route.path;
      let isAuthorized = false;
      userProfile.menus.forEach(m => {
        if (!m.items) {
          return;
        }
        m.items.forEach(mm => {
          // console.log(currentPath, 'VS', mm.path);
          if (!mm.path) {
            return;
          }
          if (currentPath.indexOf(mm.path) === 0) {
            isAuthorized = true;
          }
        });
      });

      if (userProfile.DEV_IGNORE_AUTH_CHECK) {
        // 개발설정(config.dev.json) 에서 메뉴권한 체크 무시설정이 있는경우임
        isAuthorized = true;
      }

      // console.log('checkAuth', this.$route.path, userProfile.menus, isAuthorized)
      if (isAuthorized) {
        this.AC_UPDATE_CURRENT_PAGE_AUTH('true');
        return;
      }

      this.AC_UPDATE_CURRENT_PAGE_AUTH('false');
    },

    isHasAuth(path) {
      let userProfile = this.userProfile;
      if (!userProfile || !userProfile.menus) {
        return false;
      }
      if (userProfile.DEV_IGNORE_AUTH_CHECK) {
        // 개발설정(config.dev.json) 에서 메뉴권한 체크 무시설정이 있는경우임
        return;
      }

      let isAuthorized = false;
      userProfile.menus.forEach(m => {
        if (!m.items) {
          return;
        }
        m.items.forEach(mm => {
          if (!mm.path) {
            return;
          }
          // console.log(path, 'VS', mm.path);
          if (path.indexOf(mm.path) === 0) {
            isAuthorized = true;
          }
        });
      });

      return isAuthorized;
    },

    getLoginName() {
      return this.userProfile.name || '';
    },
    getLoginKey() {
      return this.userProfile.key || '';
    }
  },
  watch: {
    userProfile: {
      deep: true,
      handler: function (n) {
        if (!n.menus) {
          return;
        }
        this.checkAuth(n);
      }
    },
  },

  computed: {
    ...mapGetters(userStore, {
      userProfile: 'GE_PROFILE'
    }),
    ...mapGetters(userStore, {
      isAuthorizedPage: 'GE_IS_AUTHORIZED_PAGE'
    }),
    ...mapGetters(userStore, {
      isOnSiteReady: 'GE_IS_ONSITE_READY'
    }),
  }
}
