<template>
  <v-navigation-drawer
    right
    floating
    clipped
    app
  >
    <!-- <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img src="https://randomuser.me/api/portraits/women/81.jpg">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Jane Smith</v-list-item-title>
          <v-list-item-subtitle>Logged In</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template> -->

    <!-- <v-divider></v-divider> -->

    <v-subheader><h3>Contents</h3></v-subheader>
    <!-- <pre>{{contentNavigation}}</pre> -->
    <ul id="default-toc">
      <!-- <v-list-item
        v-for="item in items"
        :key="item.title"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->

      <li
        v-for="(item) in contentNavigation"
        :key="item.title"
        :class="currentHash === 'navi_'+ item.id ? 'router-link-active' : ''"
      >
        <a
          
          @click="scrollTo(item.id)"
        >
          {{ item.title }}
        </a>
      </li>
    </ul>
  </v-navigation-drawer>
</template>


<script>

export default {
  
  data() {
    return {
      currentHash: '',
      headings: [],
    }
  },
  created() {

  },
  computed: {
    contentNavigation: {
      get() {
        return this.$store.state.contentNavigation;
      }
    }
  },
  methods: {
    scrollTo(refName) {
      var el = document.getElementById('navi_' + refName);

      var top = el.offsetTop;
      // window.scrollTo(0, top);
      this.$vuetify.goTo(top, {
        duration: 300,
        offset: 0,
        easing: 'linear'
      })
    },

    observeSections() {

    },
    sectionObserverHandler (entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          // const sectionId = entry.target.id
          // Push sectionId to router here 
          try {
            // this.$router.replace({ name: this.$route.name, hash: `#${sectionId}` })
          } catch(e) {
            // console.log(e);
          }
        }
      }
    },
    
  },

  watch: {
    contentNavigation() {
      let THIS = this;
      this.currentHash = null;
      setTimeout(function() {
        THIS.headings = document.querySelectorAll('div[id*="navi"]');
      }, 300);
    }
  },

  mounted() {
    let THIS = this;

    let handle = () => {
      let headings = THIS.headings;
      if (!headings || headings.length === 0) {
        THIS.headings = document.querySelectorAll('div[id*="navi"]');
        headings = THIS.headings;
      }
      document.addEventListener('scroll', () => {
        // console.log(THIS.$route.path, THIS.$route.hash);


        if (window.scrollY < 30) {
          THIS.currentHash = null;
          return;
        }


        let len = headings.length,
          i,
          ha;


        if (document.body.scrollHeight == window.innerHeight + window.scrollY) {
          THIS.currentHash = headings[len-1].id;
          return;
        }

        for (i=0; i<len; i++) {
          ha = headings[i];
          const rect = ha.getBoundingClientRect();

          if(rect.top > 0 && rect.top < 350 && THIS.currentHash !== ha.id)  {
            THIS.currentHash = ha.id;
            // console.log(ha.id, '로 변경', window.scrollY, rect.top , rect.bottom , window.innerHeight / (rect.top + rect.bottom - window.scrollY ));
          }
        }
      });
    }
    setTimeout(function() {
      handle();
    }, 500);

    setInterval(handle, 1000);
    // setTimeout(function() {
    //   try {
    //     THIS.sectionObserver.disconnect()
    //   } catch (error) {
    //     //
    //   }

    //   const options = {
    //     rootMargin: '0px 0px',
    //     threshold: 0
    //   }
    //   THIS.sectionObserver = new IntersectionObserver(THIS.sectionObserverHandler, options)
  
    //   // Observe each section
    //   const sections = document.querySelectorAll('h2 a[id]')
    //   sections.forEach(section => {
    //     THIS.sectionObserver.observe(section)
    //   })
    // }, 1000)
    // window.addEventListener("hashchange", (e) =>{
    //   e.preventDefault();
    // });


    
  },
};
</script>

<style scoped>

#default-toc {
    list-style-type: none;
}

#default-toc li {
    border-left: 4px solid #e5e5e5;
    padding: 5px 5px 5px 15px;
    font-size: 14px;
    transition-duration: 0.5s;
}

#default-toc li a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
}

#default-toc li.router-link-active a {
  color: rgb(24, 103, 192)
}

#default-toc li.router-link-active {
  border-left: 4px solid rgb(24, 103, 192);
}


</style>