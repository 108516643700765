import Vue from 'vue';
import VueRouter from 'vue-router';
import demoRouter from './demo-router';
import commonRouter from './common-router';
import onsiteRouter from './onsite-router';
import developerRouter from './developer-router';
import blogRouter from './blog-router';
import Dashboard from '@/views/Dashboard';
import JsonLint from '@/views/JsonLint';

Vue.use(VueRouter);

let routes = [
  {
    path: "/",
    redirect: '/dashboard'
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/jsonlint",
    name: "JsonLint",
    component: JsonLint
  },
]

/** 그룹별 라우터 설정 합치기 **/
routes = routes.concat(blogRouter);
routes = routes.concat(demoRouter);
routes = routes.concat(commonRouter);
routes = routes.concat(onsiteRouter);
routes = routes.concat(developerRouter);
// routes = routes.concst(blogRouter);

const router = new VueRouter({
  mode: "history", // 프러덕션 빌드시 hash => history 로 빌드해야함.
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        // behavior: 'smooth',
      }
    }
    return {
      x: 0,
      y: 0,
      // behavior: 'smooth',
    }
  }
})


router.beforeEach((to, from, next) => {
  let toPath = to.path;
  if (!/^\/(tester|blog-post-product|blog-post|shop-prod|shop-partner-prod|shop-user|product-validation|partner-product-add|partner-login)/.test(toPath)) {
    console.log('Vue3 && Vuetify3 로 이관');
    if (!location.port) {
      location.replace(toPath);
      return false;
    }
  }
  return next()
})

export default router;