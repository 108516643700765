<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <v-card>
      <template v-if="dialogType == 'prompt'">
        <v-card-title class="text-h6" v-html="message"></v-card-title>
        <v-card-text class="mt-0">
          <v-textarea v-model="promptMessage"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small @click="cancel()">
            취소
          </v-btn>
          <v-btn small :disabled="!promptMessage" color="primary" @click="ok()">
            확인
          </v-btn>
        </v-card-actions>
      </template>

      <template v-else>
        <v-card-title class="text-h5" v-if="dialogType == 'confirm'">
          계속 진행하시겠습니까?
        </v-card-title>
        <v-card-text :class="dialogType == 'confirm' ? '' : 'pt-10'" v-html="message"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small @click="cancel()" v-if="dialogType == 'confirm'">
            취소
          </v-btn>
          <v-btn small color="primary" @click="ok()">
            확인
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>

import Vue from 'vue'
export default {
  name: 'OnDialog',
  data() {
    return {
      dialog: false,
      
      dialogType: 'confirm', // confirm || alert
      message: '',
      resolve: null,

      promptMessage: '',

    }
  },
  created() {
    let THIS = this;
    Vue.prototype.confirm = function (msg){
      THIS.dialogType = 'confirm';
      THIS.message = msg;
      THIS.dialog = true
      return new Promise((resolve) => {
        THIS.resolve = resolve;
      })
    }

    Vue.prototype.alert = function (msg) {
      THIS.dialogType = 'alert';
      THIS.message = msg;
      THIS.dialog = true
      return new Promise((resolve) => {
        THIS.resolve = resolve;
      })
    }

    Vue.prototype.prompt = function (msg) {
      THIS.dialogType = 'prompt';
      THIS.message = msg;
      THIS.dialog = true
      return new Promise((resolve) => {
        THIS.resolve = resolve;
      })
    }
    window.addEventListener('keydown', this.handleKey);
  },
  methods: {

    handleKey(e) {
      if (!this.dialog || this.dialogType == 'prompt') {
        return;
      }
      if (e.key === 'Enter') {
        this.ok();
        e.preventDefault();
      } else if (e.key === 'Escape') {
        this.cancel();
        e.preventDefault();
      }
    },
    cancel() {
      if (typeof this.resolve === 'function') {
        this.resolve(false);
      }
      this.dialog = false;
    },
    ok() {
      if (typeof this.resolve === 'function') {
        if (this.dialogType == 'prompt') {
          this.resolve(this.promptMessage);
          this.promptMessage = '';
        } else {
          this.resolve(true);
        }
      }
      this.dialog = false;
    }
  },

  destroyed() {
    window.removeEventListener('keydown', this.handleKey);
  },

}
</script>
<style lang="">
  
</style>