import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';

import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader


const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        secondary: '#5cbbf6',
      },
    },
  },
});

Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})

export default vuetify
