<template>
  <v-app-bar app 
             class="pa-0"
             color="white" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;" elevation="0">
    
    <v-app-bar-nav-icon @click="setDrawer(!drawer)"></v-app-bar-nav-icon>
    <!-- 
    <div class="d-flex align-center">
      <v-img alt="Vuetify Logo" class="shrink mr-2" contain
             src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition" width="40" />

      <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
             src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" />
    </div> -->

    <!-- <v-spacer></v-spacer> -->

    <div>SSL 터널링</div>
    <!-- <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text>
      <span class="mr-2">Latest Release</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
// Utilities
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DefaultHeader',
  components: {

  },

  props: {

  },

  data: () => ({
    userType: 'USER',
  }),

  mounted() {
  },

  computed: {
    ...mapState(['drawer']),
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
  },
}
</script>
