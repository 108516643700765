<template>
  <v-app>

    <BlogHeader></BlogHeader>

    <AdCenterStyleSidebar :sidebarCollapsed="drawer" :sidebarColor="sidebarColor" :sidebarTheme="sidebarTheme">
    </AdCenterStyleSidebar>

    <ContentNavigation v-if="contentNavigation"></ContentNavigation>


    <v-main>
      <router-view v-if="isAuthorizedPage == 'true'" />
      <AutoOptsComponent></AutoOptsComponent>
    </v-main>


    <Notification></Notification>
    <DialogVue></DialogVue>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import AdCenterStyleSidebar from '@/components/Sidebars/AdCenterStyleSidebar';
import ContentNavigation from '@/components/Sidebars/ContentNavigation';

import BlogHeader from '../components/Headers/BlogHeader';
import Notification from '@/components/Notification';
import { mapGetters } from 'vuex'
const userStore = 'userStore';
import AutoOptsComponent from "@/plugins/auto-opts-component";
import DialogVue from '@/components/Dialog.vue';

export default {
  name: 'App',

  components: {
    BlogHeader,
    AdCenterStyleSidebar,
    ContentNavigation,
    Notification,
    AutoOptsComponent,
    DialogVue
  },

  data() {
    return {
      // Main sidebar color.
      sidebarColor: "primary",

      // Main sidebar theme : light, white, dark.
      sidebarTheme: "light",

      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', to: "/" },
        { title: 'Creative', icon: 'mdi-image', to: "/creative" },
        { title: 'JsonLint', icon: 'mdi-image', to: "/jsonlint" },
      ],
      right: null,




    }
  },
  mounted() {
    // installDirectives(this, {Scroll})
  },

  methods: {
    toggleSidebar(value) {
      this.sidebarCollapsed = value;
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
  },

  computed: {
    ...mapState([]),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },

    contentNavigation: {
      get() {
        return this.$store.state.contentNavigation;
      }
    },
    ...mapGetters(userStore, {
      isAuthorizedPage: 'GE_IS_AUTHORIZED_PAGE'
    }),
    
  },
};
</script>
