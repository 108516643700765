
const userStore = {
  namespaced: true,
  state: {
    profile: {
      "foo": "bar"
    },
    authButtons: [],
    isAuthorizedPage: null,

    isOnSiteReady: false,

  },
  getters: {
    GE_PROFILE(state) {
      // console.log('GE_PROFILE 호출');
      return state.profile;
    },
    GE_AUTH_BUTTONS(state) {
      // console.log('GE_PROFILE 호출');
      return state.authButtons;
    },
    GE_IS_AUTHORIZED_PAGE(state) {
      // console.log('GE_IS_AUTHORIZED_PAGE 호출');
      return state.isAuthorizedPage;
    },
    GE_IS_ONSITE_READY(state) {
      // console.log('GE_PROFILE 호출');
      return state.isOnSiteReady;
    },
  },
  mutations: {
    MU_UPDATE_PROFILE: (state, payload) => {
      // console.log('MU_UPDATE_PROFILE 호출', payload);

      if (payload.isOnSiteReady === true) {
        state.isOnSiteReady = true;
      }
      state.authButtons = payload.authButtons || [];
      delete payload.authButtons;
      state.profile = payload;
    },
    MU_UPDATE_CURRENT_PAGE_AUTH: (state, payload) => {
      // console.log('MU_UPDATE_CURRENT_PAGE_AUTH 호출', payload);
      state.isAuthorizedPage = payload
    }
  },
  actions: {
    AC_UPDATE_PROFILE: ({ commit }, payload) => {
      commit('MU_UPDATE_PROFILE', payload);
    },
    AC_UPDATE_CURRENT_PAGE_AUTH: ({ commit }, payload) => {
      commit('MU_UPDATE_CURRENT_PAGE_AUTH', payload);
    },
  }
}

export default userStore;
