export default [
  /* 소재 테스터 */
  {
    path: '/tester/creative/:type',
    children: [
      {
        path: ':id',
        component: () => import('../views/tester/CreativeTester.vue'),
      }
    ],
    name: 'Creative Tester',
    layout: "ad-center",

    component: () => import('../views/tester/CreativeTester.vue'),
  },

  /* 테그 테스터 */
  {
    path: '/tester/tag-mapper/:type',
    children: [
      {
        path: ':siteId',
        component: () => import('../views/tester/TagTester.vue'),
      }
    ],
    name: 'Tag Tester',
    component: () => import('../views/tester/TagTester.vue'),
  },

  /* 콘텐츠 관리 */
  {
    path: '/content/:group',
    children: [
      {
        path: ':id',
        component: () => import('../views/content/ItemEdit.vue'),
      }
    ],
    name: 'Content Manager',
    layout: "ad-center",

    component: () => import('../views/content/Content.vue'),
  },


  /* 블로그 포스트 관리 */
  {
    path: '/blog-post',
    name: 'Blog Post Manager',
    layout: "ad-center",

    component: () => import('../views/blog-post/BlogPost.vue'),
  },
  {
    path: '/blog-post/:category/:group?',
    name: 'Blog Post Manager ',
    layout: "ad-center",

    component: () => import('../views/blog-post/BlogPost.vue'),
  },
  {
    path: '/blog-post/:category/:group/:postId',
    name: 'Blog Post Manager (Edit)',
    layout: "ad-center",

    component: () => import('../views/blog-post/PostEdit.vue'),
  },

  {
    path: '/blog-post-product',
    alias: ['/blog-post-product/list'],
    name: 'Blog Post Product List',
    layout: "ad-center",
    component: () => import('../views/blog-post-product/ProductList.vue'),
  },

  {
    path: '/blog-post-product/list/:tab',
    name: 'Blog Post Product List ',
    layout: "ad-center",
    component: () => import('../views/blog-post-product/ProductList.vue'),
  },
  {
    path: '/blog-post-product/item/:productId',
    name: 'Blog Post Product Item',
    layout: "ad-center",
    component: () => import('../views/blog-post-product/ProductEdit.vue'),
  },
  {
    path: '/blog-post-product/add/:productId',
    name: 'Blog Post Add Product',
    layout: "ad-center",
    component: () => import('../views/blog-post-product/ProductEdit.vue'),
  },

  {
    path: '/blog-post-manage',
    children: [
      {
        path: ':category'
      }
    ],
    name: '포스트 목록 관리',
    layout: "ad-center",
    component: () => import('../views/blog-post-manage/PostList.vue'),
  },


  {
    path: '/gif-builder',
    name: 'GIF 테스트',
    layout: "ad-center",
    component: () => import('../views/gif-builder/GifBuilder.vue'),
  },




  /* 쇼핑 상품관리 */
  {
    path: '/shop-prod',
    alias: ['/shop-prod-list'],
    name: '쇼핑 상품목록',
    layout: "ad-center",
    component: () => import('../views/shop-prod/ProductList.vue'),
  },
  {
    path: '/shop-prod/:step/:productId',
    name: '상품 아이템',
    layout: "ad-center",
    component: () => import('../views/shop-prod/ProductItem.vue'),
  },
  {
    path: '/shop-user/setting',
    name: '사용자 환경 설정',
    layout: "ad-center",
    component: () => import('../views/shop-prod/UserSetting.vue'),
  },
  {
    path: '/product-validation',
    name: '상품검증',
    children: [
      {
        path: ':makeshopProductId',
      }
    ],
    layout: "ad-center",
    component: () => import('../views/shop-prod/ProductValidation.vue'),
  },
  {
    path: '/shop-partner-prod',
    name: '제휴상품 검수 목록',
    component: () => import('../views/shop-prod/PartnerProductList.vue'),
  },
  {
    path: '/shop-partner-prod/:partnerId/:step/:ts',
    name: '제휴상품 검수 상세',
    component: () => import('../views/shop-prod/ConfirmProduct.vue'),
  },

  /* 쇼핑몰 공급사 파트너 페이지 */
  {
    path: '/partner-product-add',
    alias: ['/partner-product-add/basic'],
    name: '제휴상품 등록',
    meta: {
      layout: 'alone',
    },
    children: [
      {
        path: ':step'
      },
      {
        path: ':step/:ts'
      }
    ],
    component: () => import('../views/shop-patner/AddProduct.vue'),
  },
  {
    path: '/partner-login',
    name: '공급사 로그인',
    meta: {
      layout: 'alone',
    },
    component: () => import('../views/shop-patner/PartnerLogin.vue'),
  },

]
