<template>
  <div class="ma-5">
    ...
  </div>
</template>
<script>
export default {
  data () {
    return {
      
    }
  },
}
</script>
<style lang="">
  
</style>