export default [
  {
    path: '/admin',
    name: '관리자',
    layout: "ad-center",
  },
  {
    path: '/admin/member',
    alias: ['/admin/member/list'],
    name: '회원 목록',
    layout: "ad-center",
    component: () => import('../views/admin/MemberList.vue'),
  },
  {
    path: '/admin/member/detail/:key',
    name: '회원 상세',
    layout: "ad-center",
    meta: {
      parentMenu: '/admin/member/list'
    },
    component: () => import('../views/admin/MemberDetail.vue'),
  },
  {
    path: '/admin/auth',
    name: '회원 권한관리',
    layout: "ad-center",
    component: () => import('../views/admin/MemberAuth.vue'),
  },
];
