
function getNum(num, digits, trim) {
  if (!trim && !digits) { digits = 2; }
  num = '' + num;
  while (num.length < digits) { num = '0' + num; }
  if (trim) {
    num = num.substr(num.length - digits);
  }
  return num;
}

function getYesterdayYMD(date) {
  date = date ? date : new Date();
  date.setDate(date.getDate() - 1); //하루 전
  return date.getFullYear() + '-' + getNum(date.getMonth() + 1) + '-' + getNum(date.getDate());
}

function getTomorrowYMD(date) {
  date = date ? date : new Date();
  date.setDate(date.getDate() + 1); //내일
  return date.getFullYear() + '-' + getNum(date.getMonth() + 1) + '-' + getNum(date.getDate());
}

function getYMD(date, isContainHourMinute) {
  date = date ? date : new Date();
  var mm = date.getMonth() + 1;
  var dd = date.getDate();

  if (isContainHourMinute) {
    return [date.getFullYear(), mm < 10 ? '0' + mm : mm, dd < 10 ? '0' + dd : dd].join('-') + ' ' + getNum(date.getHours()) + ':' + getNum(date.getMinutes());
  } else {
    return [date.getFullYear(), mm < 10 ? '0' + mm : mm, dd < 10 ? '0' + dd : dd].join('-');
  }
}

function getYmdHm(dt) {
  let d = new Date(dt);
  return getYMD(d, true);
}

export default {
  getYmdHm: getYmdHm,
  getYMD: getYMD,
  getYesterdayYMD: getYesterdayYMD,
  getTomorrowYMD: getTomorrowYMD
}