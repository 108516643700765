<!--
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
const blogLayouts = [
  '/article'
];

const aloneLayouts = [
  '/partner-product-add',
  '/partner-login',
];
export default ({
  computed: {
    // Sets components name based on current route's specified layout, defaults to
    // <layout-default></layout-default> component.
    layout() {
      // CHECK: $route 정보가 / 에서 /article 로 두번에 걸쳐 처리되는데, 이유를 모르겠네...
      let meta = this.$route.meta;
      let path = location.href.split(location.host)[1];
      blogLayouts.map(p => {
        if (path.indexOf(p) === 0) {
          meta.layout = 'blog';
        }
      })
      aloneLayouts.map(p => {
        if (path.indexOf(p) === 0) {
          meta.layout = 'alone';
        }
      })
      return "layout-" + (meta.layout || "default").toLowerCase();
    }
  },
  created() {
    // console.log('created', this.$route.path)
  }
})

</script>

<style lang="scss">
</style>
