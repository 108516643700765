import Vue from 'vue'

let aceEditor = Vue.component('AceEditor', {
  template: '<div :id="editorId" style="width: 100%;"></div>',
  props: ['editorId', 'content', 'lang', 'theme', 'config', 'saveFunction', 'changeSelection'],
  data() {
    return {
      editor: Object,
      beforeContent: ''
    }
  },
  watch: {
    content(value) {
      if (this.beforeContent !== value) {
        this.editor.getSession().setMode(`ace/mode/${this.lang}`)
        this.editor.setValue(value, 1)
      }
    },
  },
  methods: {
    doResize() {
      // console.log('ace doResize')
      // this.editor.resize();
      // this.editor.renderer.updateFull();

    },
    handleEvent(e) {
      if (e.key === 's' && e.metaKey) {
        e.preventDefault();
        e.stopPropagation();
        if (typeof this.saveFunction === 'function') {
          this.saveFunction();
        }
      }
    }
  },
  mounted() {
    const lang = this.lang || 'text'
    const theme = this.theme || ''

    let config = this.config || {};

    this.editor = window.ace.edit(this.editorId)
    // console.log('ace mounted')
    this.editor.setValue(this.content || '', 1)
    this.editor.gotoLine(0);

    this.editor.getSession().setMode(`ace/mode/${lang}`)
    if (theme) {
      this.editor.setTheme(`ace/theme/${theme}`)
    }
    this.editor.setAutoScrollEditorIntoView(true);

    if (config.maxLines) {
      this.editor.setOption("maxLines", config.maxLines);
    }
    this.editor.setOption('tabSize', 2);
    this.editor.on('change', () => {
      this.beforeContent = this.editor.getValue()
      this.$emit('change-content', this.editor.getValue(), this.editorId)
    })

    if (typeof this.changeSelection === 'function') {
      let THIS = this;
      this.editor.on('changeSelection', function () {
        THIS.changeSelection(THIS.editor);
      });
    }

    if (typeof this.saveFunction === 'function') {
      window.addEventListener("keydown", this);
    }
  },
  created() {
  },
  beforeDestroy() {
    this.editor.destroy();
    if (typeof this.saveFunction === 'function') {
      window.removeEventListener("keydown", this);
    }
  }
})

export default aceEditor
