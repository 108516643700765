<template>
  <v-container fluid>
    <h1>JsonLint DEMO</h1>

    <v-row>
      <v-col cols="12">
        <v-card>
          <!-- https://ace.c9.io/build/kitchen-sink.html -->
          <ace-editor editor-id="jsonLintDemo" lang="json" :content="jsonData" v-on:change-content="changeData"
                      theme="dawn" :config="editorConfig" :style="editorHeight">
          </ace-editor>
        </v-card>

        <v-card min-height="136"
                :class="{ 'red darken-1 yellow--text': errorMessage, 'green lighten-5': !errorMessage }"
                style="margin-top: 20px;">

          <pre class="pa-5" v-if="errorMessage">{{errorMessage}}</pre>
          <div class="pa-5" v-if="!errorMessage">
            <v-btn elevation="2" @click="doFormat()">정렬하기</v-btn>
            <v-btn elevation="2" @click="doMakeSingleLine()" class="ml-5">한줄로 만들기</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import AutoOptsComponent from "@/plugins/auto-opts-component";
import AceEditor from '../plugins/ace-editor';

var sampleData = {"id": "adxr_20210804090902200368013577", "at": 1, "tmax": 200, "bcat": [], "badv": ["wish.com", "smartworldtraffic.com", "citybuymall.com", "ctbuymall.com", "citybuymall.com", "ctbuymall.com"], "cur": ["USD"], "imp": [{"id": "adxi_20210804090902200373063435", "tagid": "26537673", "bidfloor": 0.0625, "bidfloorcur": "USD", "instl": 1, "secure": 1, "banner": {"battr": [], "btype": [4], "api": [], "w": 320, "h": 480}}], "app": {"id": "19385814", "publisher": {"id": "1164"}, "name": "com.avatye.aos.haru", "bundle": "com.avatye.aos.haru", "storeurl": "https://play.google.com/store/apps/details?id=com.avatye.aos.haru"}, "device": {"os": "android", "osv": "10", "model": "SM-N960N", "ua": "Dalvik/2.1.0 (Linux; U; Android 10; SM-N960N Build/QP1A.190711.020)", "ip": "118.219.142.133", "geo": {"country": "KOR"}, "w": 320, "h": 480, "carrier": "SKTelecom", "language": "ko", "connectiontype": 0, "dnt": 0, "ifa": "68831ad5-1b4e-4063-8968-7b6cad4e5f64"}, "regs": {"coppa": 0, "ext": {"gdpr": 0}}, "user": {"id": "68831ad5-1b4e-4063-8968-7b6cad4e5f64", "buyerid": "", "buyeruid": ""}, "test": 0, "ext": {"bm": 5}};

export default ({
  mixins: [AutoOptsComponent],
  comments: [
    AceEditor
  ],
  data ()  {
    return {
      auto_opts: {
        sampleData: ''
      },
      jsonData: '',
      errorMessage: '',
      editorHeight: 0,
      editorConfig: {
        // maxLines: 100
      }
    }
  },
  methods: {
    changeData (val) {
      if (this.jsonData !== val) {
        this.jsonData = val
        try {
          window.jsonlint.parse(val)
          this.errorMessage = '';
        } catch(e) {
          this.errorMessage = e;
        }
      }
    },
    doResize () {
      this.editorHeight = 'height: ' + (window.innerHeight - 357) + 'px';
    },
    doFormat () {
      this.auto_opts.sampleData = JSON.parse(this.jsonData);
      this.jsonData = JSON.stringify(JSON.parse(this.jsonData), null,2)
    },
    doMakeSingleLine() {
      this.auto_opts.sampleData = JSON.parse(this.jsonData);
      this.jsonData = JSON.stringify(JSON.parse(this.jsonData))
    },
  },
  created() {
    this.doResize();
    window.addEventListener("resize", this.doResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.doResize);
  },
  mounted() {
    this.jsonData = JSON.stringify(this.auto_opts.sampleData || sampleData, null, 2)
  },
})

</script>
