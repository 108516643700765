export default [
  {
    path: '/todolist',
    name: 'TodoList (DEMO)',
    layout: "ad-center",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Todo.vue'),
  },
  {
    path: '/layout',
    name: 'Layout',
    layout: "ad-center",
    component: () => import('../views/Layout.vue'),
  },
  {
    path: '/tables',
    name: 'Tables',
    layout: "ad-center",
    component: () => import('../views/Tables.vue'),
  },
  {
    path: '/vuetify-tables',
    name: 'Vuetify TEST',
    layout: "ad-center",
    component: () => import('../views/VuetifyTables.vue'),
  },
  {
    path: '/vuetify-tables',
    name: 'Tables (Vuetify)',
    layout: "ad-center",
    component: () => import('../views/VuetifyTables.vue'),
  },
  {
    path: '/tiptap-demo',
    name: 'TipTap Righ Editor',
    layout: "ad-center",
    component: () => import('../views/TiptapDemo.vue'),
  },
  {
    path: '/demo/ace-editor-demo',
    name: 'Ace & JsonDiffPatch',
    layout: "ad-center",
    component: () => import('../views/AceEditorDemo'),
  },
  {
    path: '/demo/jsonlint-demo',
    name: 'JsonLint Demo',
    layout: "ad-center",
    component: () => import('../views/JsonLint'),
  },
  {
    path: '/demo/server-log',
    name: '서버 로그 (Socket.IO 데모)',
    layout: "ad-center",
    component: () => import('../views/demo/ServerLog'),
  },
]
