import axios from 'axios';
import shopProdUtils from '@/views/shop-prod/shop-prod-utils';
import _ from 'lodash';

const shopProductStore = {
  namespaced: true,
  state: {
    cdnFiles: [],
    cdnFilesDiff: null, // CDN 이미지와 로컬이미지 DIFF 결과 (이미지 목록 포함)
    isProcessing: false,
    sabangnetToken: null,  // 사방넷 토큰정보
    body_option_preview: null, // body 하위 옵션 미리보기 위젯정보
    makeshop_sabang_data: null, // 메이크샵/사방넷 등록화면에서 사용할 비교데이터 정보
  },
  getters: {
    GE_CDN_FILES(state) {
      return state.cdnFiles;
    },
    GE_CDN_FILES_DIFF(state) {
      return state.cdnFilesDiff;
    },
    GE_IS_PROCESSING(state) {
      return state.isProcessing;
    },
    GE_SABANGNET_TOKEN(state) {
      return state.sabangnetToken;
    },
    GET_BODY_OPTION_PREVIEW(state) {
      return state.body_option_preview;
    },
    GET_MAKESHOP_SABANG_DATA(state) {
      return _.cloneDeep(state.makeshop_sabang_data);
    }
  },
  mutations: {
    MU_UPDATE_CDN_FILES: (state, payload) => {
      // console.log('MU_UPDATE_CDN_FILES 호출', payload);
      state.cdnFiles = payload;
    },
    MU_UPDATE_CDN_FILES_DIFF: (state, payload) => {
      state.cdnFilesDiff = payload;
    },
    MU_UPDATE_IS_PROCESSING: (state, payload) => {
      state.isProcessing = payload;
    },
    MU_SABANGNET_TOKEN: (state, payload) => {
      state.sabangnetToken = payload;
    },
    MU_BODY_OPTION_PREVIEW: (state, payload) => {
      state.body_option_preview = payload;
    },
    MU_MAKESHOP_SABANG_DATA: (state, payload) => {
      state.makeshop_sabang_data = payload;
    },
  },
  actions: {
    AC_UPDATE_CDN_FILES: ({ commit }, payload) => {
      commit('MU_UPDATE_CDN_FILES', payload.cdnFiles);
      let api = `/service/shop/image/images?id=${payload.productId}`;
      axios.get(api).then(o => {
        if (o.data.code !== 'SUCCESS') {
          console.error('로컬이미지정보 가져오기 실패!');
          return;
        }
        commit('MU_UPDATE_CDN_FILES_DIFF', shopProdUtils.diffImages(payload.cdnFiles, o.data.data));
      });
    },
    AC_UPDATE_IS_PROCESSING: ({ commit }, payload) => {
      commit('MU_UPDATE_IS_PROCESSING', payload);
    },
    AC_CHECK_SABANGNET_TOKEN: ({ commit }) => {
      axios.get('/service/shop/user/getSetting').then(o => {
        if (o.data.code !== 'SUCCESS' || !o.data.data.sabangnetToken) {
          commit('MU_SABANGNET_TOKEN', {});
          return;
        }
        commit('MU_SABANGNET_TOKEN', o.data.data.sabangnetToken);
      });
    },
    AC_UPDATE_BODY_OPTION_PREVIEW: ({ commit }, payload) => {
      commit('MU_BODY_OPTION_PREVIEW', payload);
    },
    AC_UPDATE_MAKESHOP_SANGA_DATA: ({ commit }, payload) => {
      commit('MU_MAKESHOP_SABANG_DATA', payload);
    },
    AC_FETCH_MAKESHOP_SANGA_DATA: ({ commit }, payload) => {
      commit('MU_MAKESHOP_SABANG_DATA', null);
      let retryCount = 1;
      let tryGet = function () {
        let api = `/service/shop/product/makeshop_to_local?id=${payload.makeshopProductId}&productId=${payload.productId}&diff=validation`;
        axios.get(api).then(o => {
          if (o.data.code !== 'SUCCESS') {
            console.error(o.data);
            commit('MU_MAKESHOP_SABANG_DATA', null);
            if (retryCount > 0) {
              retryCount--;
              tryGet();
            }
            return;
          }
          commit('MU_MAKESHOP_SABANG_DATA', o.data.data);
        });
      }
      tryGet();



    },
  }
}

export default shopProductStore;
